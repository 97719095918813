import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  activeCountriesResponseMap,
  filterParametersResponseMap,
  masterDataResponseMap,
} from '../../helpers';
import {
  AppStorage,
  GetOpStructuresResponse,
  GetPosTypesResponse,
  SalesApi,
  SalesParametersApi,
  GetActiveCountriesResponse,
  GetParametersResponse,
} from '../../services';
import { ActionType as UserActionType } from '../user/types';
import {
  activeCountriesFailure,
  activeCountriesRequested,
  activeCountriesSuccess,
  masterDataFailure,
  masterDataRequested,
  masterDataSuccess,
  parametersFailure,
  parametersRequested,
  parametersSuccess,
} from './actionCreators';

import { HttpRequestError } from '../../types';
import { findAllRequest } from '../dashboard/actionCreators';

function* getAppParameters() {
  yield put(masterDataRequested());

  try {
    let masterData = AppStorage.getMasterData();

    if (!masterData || !masterData.federativeEntities) {
      const [opStructuresResponse, posTypesResponse, federativeEntitiesResponse]: [
        AxiosResponse<GetOpStructuresResponse>,
        AxiosResponse<GetPosTypesResponse>,
        AxiosResponse<Record<string, string[]>>
      ] = yield all([
        call(SalesApi.getOpStructures),
        call(SalesParametersApi.getPosTypes),
        call(SalesParametersApi.getFederativeEntities),
      ]);

      const { data: opStructuresData } = opStructuresResponse;
      const { data: posTypesData } = posTypesResponse;
      const { data: federativeEntitiesData } = federativeEntitiesResponse;

      masterData = masterDataResponseMap(opStructuresData, posTypesData, federativeEntitiesData);

      AppStorage.setMasterData(masterData);
    }

    yield put(masterDataSuccess(masterData));
  } catch (error) {
    yield put(masterDataFailure(error as HttpRequestError));
  }
}

function* getActiveCountries() {
  yield put(activeCountriesRequested());

  try {
    const activeCountriesResponse: AxiosResponse<GetActiveCountriesResponse> = yield call(
      SalesParametersApi.getActiveCountries
    );

    const { data: activeCountriesData } = activeCountriesResponse;

    const activeCountries = activeCountriesResponseMap(activeCountriesData);

    yield put(activeCountriesSuccess(activeCountries));
    yield put(findAllRequest());
  } catch (error) {
    yield put(activeCountriesFailure(error as HttpRequestError));
  }
}

function* getParameters() {
  yield put(parametersRequested());
  try {
    const parametersResponse: AxiosResponse<GetParametersResponse> = yield call(
      SalesParametersApi.getParameters
    );
    const { data: parametersData } = parametersResponse;
    const parameters = filterParametersResponseMap(parametersData);
    yield put(parametersSuccess(parameters));
  } catch (error) {
    yield put(parametersFailure(error as HttpRequestError));
  }
}

export default [
  takeLatest(UserActionType.permissionsSuccess, getAppParameters),
  takeLatest(UserActionType.permissionsSuccess, getActiveCountries),
  takeLatest(UserActionType.permissionsSuccess, getParameters),
];
